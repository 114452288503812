import { get, post, del, put as update } from "helpers/api_helper";

const getPagingRequest = async params => {
  return await get("/api/ShippingContact/paging", {
    params,
  });
};

const createRequest = async item => {
  const { userId } = item;
  return await post(`/api/Account/user/${userId}​/shippingAddress`, item);
};

const getByUserId = async userId => {
  return await get(`/api/Account/user/${userId}/shippingAddress`);
};

const getDefaultByUserId = async userId => {
  return await get(`/api/Account/user/${userId}/defaultShippingAddress`);
};

const getById = async ({ userId, id }) => {
  return await get(`/api/Account/user/${userId}/shippingAddress/${id}`);
};

export const shippingContactService = {
  getPagingRequest,
  createRequest,
  getByUserId,
  getById,
  getDefaultByUserId,
};
