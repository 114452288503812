import {
  COMPANY_ADD_NEW,
  COMPANY_ADD_NEW_ERROR,
  COMPANY_ADD_NEW_SUCCESS,
  COMPANY_DELETE,
  COMPANY_DELETE_ERROR,
  COMPANY_DELETE_SUCCESS,
  COMPANY_GET_BY_ID,
  COMPANY_GET_BY_ID_ERROR,
  COMPANY_GET_BY_ID_SUCCESS,
  COMPANY_GET_PAGING,
  COMPANY_GET_PAGING_ERROR,
  COMPANY_GET_PAGING_SUCCESS,
  COMPANY_UPDATE,
  COMPANY_UPDATE_ERROR,
  COMPANY_UPDATE_SUCCESS,
} from './actionTypes';

export const getPagingCompany = items => ({
  type: COMPANY_GET_PAGING,
  payload: items,
});

export const getPagingCompanySuccess = items => ({
  type: COMPANY_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingCompanyError = error => ({
  type: COMPANY_GET_PAGING_ERROR,
  payload: error,
});

export const addCompany = items => ({
  type: COMPANY_ADD_NEW,
  payload: items,
});

export const addCompanySuccess = items => ({
  type: COMPANY_ADD_NEW_SUCCESS,
  payload: items,
});

export const addCompanyError = error => ({
  type: COMPANY_ADD_NEW_ERROR,
  payload: error,
});

export const getCompanyById = items => ({
  type: COMPANY_GET_BY_ID,
  payload: items,
});

export const getCompanyByIdSuccess = items => ({
  type: COMPANY_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getCompanyByIdError = error => ({
  type: COMPANY_GET_BY_ID_ERROR,
  payload: error,
});

export const updateCompany = items => ({
  type: COMPANY_UPDATE,
  payload: items,
});

export const updateCompanySuccess = items => ({
  type: COMPANY_UPDATE_SUCCESS,
  payload: items,
});

export const updateCompanyError = error => ({
  type: COMPANY_UPDATE_ERROR,
  payload: error,
});

export const deleteCompany = items => ({
  type: COMPANY_DELETE,
  payload: items,
});

export const deleteCompanySuccess = items => ({
  type: COMPANY_DELETE_SUCCESS,
  payload: items,
});

export const deleteCompanyError = error => ({
  type: COMPANY_DELETE_ERROR,
  payload: error,
});
