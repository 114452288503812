import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";

export class PermissionRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  
  handleCheckbox = e => {
    const { permissionGroup, onChange } = this.props;
    const { name, checked } = e.target;
    let nameProperCase ="";

    this.setState({ [e.target.name]: e.target.checked });

    switch (name) {
      case "enable":
        nameProperCase = "Enable";
        break;
      case "enableOnMobile":
        nameProperCase = "EnableOnMobile";
        break;
      case "export":
        nameProperCase = "Export";
        break;
      case "read":
        nameProperCase = "Read";
        break;
      case "write":
        nameProperCase = "Write";
        break;
      case "delete":
        nameProperCase = "Delete";
        break;
      default:
        break;
    }

    onChange({
      ...permissionGroup,
      permissions: permissionGroup.permissions.map(permission => {
        return permission.systemName.endsWith(nameProperCase) ? { ...permission, enable: checked } : permission;
      })
    });
  };
  
  render() {
    const { permissionGroup } = this.props;
    const { name, permissions } = permissionGroup;

    return (
      <Card className="border mb-1">
        <CardBody className="pt-3 pb-3">
          <Row>
            <Col md={ 3 }>
              <h6 className="fw-bold">{ name }</h6>
            </Col>
            <Col md={ 9 } className="float-end">
              <Row>
                <Col md={ 2 }>
                  { this.state.enableOnMobile !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="enableOnMobile"
                        className="form-check-input"
                        checked={ this.state.enableOnMobile }
                        onChange={ this.handleCheckbox }
                      />
                      <label className="form-check-label">
                        Enable On Mobile
                      </label>
                    </div>
                  ) }
                </Col>
                <Col md={ 2 }>
                  { permissions.find(v => v.systemName.endsWith("Enable")) !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="enable"
                        className="form-check-input"
                        checked={ permissions.find(v => v.systemName.endsWith("Enable"))?.enable }
                        onChange={ this.handleCheckbox }
                      />
                      <label className="form-check-label">Is Enable?</label>
                    </div>
                  ) }
                </Col>
                <Col md={ 2 }>
                  { permissions.find(v => v.systemName.endsWith("Read")) !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="read"
                        className="form-check-input"
                        checked={ permissions.find(v => v.systemName.endsWith("Read"))?.enable }
                        onChange={ this.handleCheckbox }
                      />
                      <label className="form-check-label">Read</label>
                    </div>
                  ) }
                </Col>
                <Col md={ 2 }>
                  { permissions.find(v => v.systemName.endsWith("Write")) !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="write"
                        className="form-check-input"
                        checked={ permissions.find(v => v.systemName.endsWith("Write"))?.enable }
                        onChange={ this.handleCheckbox }
                      />
                      <label className="form-check-label">Write</label>
                    </div>
                  ) }
                </Col>
                <Col md={ 2 }>
                  { permissions.find(v => v.systemName.endsWith("Delete")) !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="delete"
                        className="form-check-input"
                        checked={ permissions.find(v => v.systemName.endsWith("Delete"))?.enable }
                        onChange={ this.handleCheckbox }
                      />
                      <label className="form-check-label">Delete</label>
                    </div>
                  ) }
                </Col>
                <Col md={ 2 }>
                  { permissions.find(v => v.systemName.endsWith("Export")) !== undefined && (
                    <div className="form-check form-check-primary">
                      <input
                        type="checkbox"
                        name="export"
                        className="form-check-input"
                        checked={ permissions.find(v => v.systemName.endsWith("Export"))?.enable }
                        onChange={ this.handleCheckbox }
                      />
                      <label className="form-check-label">Export</label>
                    </div>
                  ) }
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

PermissionRow.propTypes = {
  index: PropTypes.number,
  permissionGroup: PropTypes.object,
  onChange: PropTypes.func,
  enableAll: PropTypes.bool,
  readAll: PropTypes.bool,
  writeAll: PropTypes.bool,
  deleteAll: PropTypes.bool
};

export default PermissionRow;
