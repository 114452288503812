// Company
export const COMPANY_ADD_NEW = 'COMPANY_ADD_NEW';
export const COMPANY_ADD_NEW_SUCCESS = 'COMPANY_ADD_NEW_SUCCESS';
export const COMPANY_ADD_NEW_ERROR = 'COMPANY_ADD_NEW_ERROR';

export const COMPANY_GET_PAGING = 'COMPANY_GET_PAGING';
export const COMPANY_GET_PAGING_SUCCESS = 'COMPANY_GET_PAGING_SUCCESS';
export const COMPANY_GET_PAGING_ERROR = 'COMPANY_GET_PAGING_ERROR';

export const COMPANY_GET_BY_ID = 'COMPANY_GET_BY_ID';
export const COMPANY_GET_BY_ID_SUCCESS = 'COMPANY_GET_BY_ID_SUCCESS';
export const COMPANY_GET_BY_ID_ERROR = 'COMPANY_GET_BY_ID_ERROR';

export const COMPANY_UPDATE = 'COMPANY_UPDATE';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR';

export const COMPANY_DELETE = 'COMPANY_DELETE';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_ERROR = 'COMPANY_DELETE_ERROR';
