import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";

class RoleTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onConfirmDelete,
      onChangePage,
      onPageSizeChange,
      onViewDetail,
    } = this.props;
    return (
      <div>
        <Card>
          <CardBody className="p-2">
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => onViewDetail(item.id)}
                          >
                            <td>{item.name}</td>
                            <td>
                              <div className="text-center">
                                <UncontrolledDropdown>
                                  <DropdownToggle className="card-drop" tag="a">
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => onEdit(item.id)}>
                                      <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => onViewDetail(item.id)}
                                    >
                                      <i className="mdi mdi-account-details font-size-16 text-success me-1" />{" "}
                                      Details
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => onConfirmDelete(item.id)}
                                    >
                                      <i className="mdi mdi-delete font-size-16 text-danger me-1" />{" "}
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
       </Card>
      </div>
    );
  }
}

RoleTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onViewDetail: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default RoleTable;
// const mapStateToProps = () => {
//   return {};
// };

// export default withRouter(connect(mapStateToProps, {})(RoleTable));
// RoleTable.prototype = {
//   items: PropTypes.array.isRequired,
// };

// import PropTypes from 'prop-types'
// import React, { Component } from 'react'
// import { connect } from 'react-redux'

// export class RoleTable extends Component {
//   static propTypes = {
//     second: third
//   }

//   render() {
//     return (
//       <div>RoleTable</div>
//     )
//   }
// }

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(RoleTable)
