import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import PermissionRow from "containers/permission/PermissionRow";

export class PermissionTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      permissionGroups: [],
      enableAll: false,
      readAll: false,
      writeAll: false,
      deleteAll: false
    };
  }

  componentDidMount() {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { item } = props;

    if (item) {
      const { id } = item;

      if (id !== state.id) {
        return {
          ...item
        };
      }
    }

    return null;
  }

  handlePermissionRowChange = item => {
    this.setState(prevState => ({
      permissionGroups: prevState.permissionGroups.map(obj => {
        return obj.name === item.name ? item : obj;
      })
    }));
  };

  handleSubmit = () => {
    const { id, name, permissionGroups, enableAll, readAll, writeAll, deleteAll } = this.state;

    const item = {
      id,
      name,
      permissionGroups,
      enableAll,
      readAll,
      writeAll,
      deleteAll
    };

    this.props.onSubmit(item);
    console.log("Submit data:", item);
  };

  handleCheckboxChange = (event) => {
    const { permissionGroups } = this.state;
    const { name, checked } = event.target;
    let nameProperCase = "";
    switch (name) {
      case "enableOnMobileAll":
        nameProperCase = "EnableOnMobile";
        break;
      case "enableAll":
        nameProperCase = "Enable";
        break;
      case "readAll":
        nameProperCase = "Read";
        break;
      case "writeAll":
        nameProperCase = "Write";
        break;
      case "deleteAll":
        nameProperCase = "Delete";
        break;
      case "exportAll":
        nameProperCase = "Export";
        break;
    }

    this.setState(prevState => ({
      ...prevState,
      permissionGroups: prevState.permissionGroups.map(obj => {
        return {
          ...obj,
          permissions: obj.permissions.map(permission => {
            return permission.systemName.endsWith(nameProperCase) ? {
              ...permission,
              enable: checked
            } : permission;
          })
        };
      })
    }));

    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { permissionGroups, enableAll, readAll, writeAll, deleteAll } = this.state;

    return (
      <Row>
        <Col md={ 12 }>
          <Card className="mb-3">
            <CardBody>
              <Card className="border mb-1">
                <CardBody className="pt-3 pb-3">
                  <Row>
                    <Col md={ 3 }>
                      <h6 className="fw-bold">Check All</h6>
                    </Col>
                    <Col md={ 9 } className="float-end">
                      <Row>
                        <Col md={ 2 }>
                          <div className="form-check form-check-primary">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="enableCheckbox"
                              name="enableOnMobileAll"
                              // checked={ enableAll }
                              onChange={ this.handleCheckboxChange }
                            />
                            <label className="form-check-label" htmlFor="enableCheckbox">
                              Enable On Mobile all
                            </label>
                          </div>
                        </Col>
                        <Col md={ 2 }>
                          <div className="form-check form-check-primary">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="enableCheckbox"
                              name="enableAll"
                              checked={ enableAll }
                              onChange={ this.handleCheckboxChange }
                            />
                            <label className="form-check-label" htmlFor="enableCheckbox">
                              Enable All
                            </label>
                          </div>
                        </Col>
                        <Col md={ 2 }>
                          <div className="form-check form-check-primary">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="enableCheckbox"
                              name="readAll"
                              checked={ readAll }
                              onChange={ this.handleCheckboxChange }
                            />
                            <label className="form-check-label" htmlFor="enableCheckbox">
                              Read All
                            </label>
                          </div>
                        </Col>
                        <Col md={ 2 }>
                          <div className="form-check form-check-primary">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="enableCheckbox"
                              name="writeAll"
                              checked={ writeAll }
                              onChange={ this.handleCheckboxChange }
                            />
                            <label className="form-check-label" htmlFor="enableCheckbox">
                              Write All
                            </label>
                          </div>
                        </Col>
                        <Col md={ 2 }>
                          <div className="form-check form-check-primary">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="enableCheckbox"
                              name="deleteAll"
                              checked={ deleteAll }
                              onChange={ this.handleCheckboxChange }
                            />
                            <label className="form-check-label" htmlFor="enableCheckbox">
                              Delete All
                            </label>
                          </div>
                        </Col>
                        <Col md={ 2 }>
                          <div className="form-check form-check-primary">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="exportAll"
                              name="exportAll"
                              // checked={ exportAll }
                              onChange={ this.handleCheckboxChange }
                            />
                            <label className="form-check-label" htmlFor="enableCheckbox">
                              Export All
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              { permissionGroups &&
                permissionGroups.map((permissionGroup, index) => {
                  return (
                    <PermissionRow
                      index={ index }
                      permissionGroup={ permissionGroup }
                      key={ index }
                      onChange={ this.handlePermissionRowChange }
                      enableAll={ enableAll }
                      readAll={ readAll }
                      writeAll={ writeAll }
                      deleteAll={ deleteAll }
                    />
                  );
                }) }
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="float-end">
                <Button color="primary" onClick={ this.handleSubmit }>
                  Submit
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

PermissionTab.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func
};

export default PermissionTab;
