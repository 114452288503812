import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button, Badge
} from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
import { getDeliveryStatusColor, hasAccess } from "../../helpers/utils";
import { permission } from "../../constants/permission";
import avatar from "../.././assets/images/avatar.png";

const WalletCard = ({ item, num, onEdit, onConfirmDelete, onToggle }) => {
  const { userFirstName, userLastName, balance, currencyLabel, userUniqueId, isDisabled, pendingCount } = item;
  const profileImage = avatar;
  let color = "primary";
  switch (currencyLabel) {
    case "KRW":
      color = "primary";
      break;
    case "USD":
      color = "success";
      break;
    case "KHR":
      color = "warning";
      break;
  }

  return (
    <Card
      className={ `border-${ color } mb-2` }
      onDoubleClick={ () => onEdit(item.id) }
      style={ {
        cursor: "pointer",
        borderLeftColor: "",
        borderLeftWidth: "10px"
      } }
    >
      <CardHeader className="bg-transparent border-bottom p-0 d-flex align-items-center">
        <div style={ { width: "150px", display: "flex", justifyContent: "center", alignItems: "center" } }>
          { item.userAvatar ? (
            <img
              src={ item.userAvatar }
              style={ { width: "50px", height: "50px" } }
              className="rounded-circle"
              alt=""
            />
          ) : (
            <img
              src={ profileImage }
              style={ { width: "50px", height: "50px" } }
              className="rounded-circle"
              alt=""
            />
          ) }
        </div>

        <div className="flex-grow-1">
          <CardHeader className={ "pb-0 border-bottom bg-transparent ps-0 ml-0" }>
            <Row style={ { cursor: "pointer" } }>
              <Col>
                <CardTitle className="mb-0 pb-0">{ `${ userFirstName } ${ userLastName }` }</CardTitle>
              </Col>
              <Col md={ 1 } className="text-end">
                <div className="d-flex flex-row justify-content-end">
                  {
                    pendingCount > 0 ?
                      <div>
                        <Badge className="px-2 py-1" pill color="danger">{ pendingCount }</Badge>
                      </div> : null
                  }
                  <UncontrolledDropdown className="px-2">
                    <DropdownToggle className="card-drop text-black" tag="a">
                      <i className="mdi mdi-dots-horizontal font-size-24" />
                    </DropdownToggle>
                    <DropdownMenu end className="text-primary">
                      <DropdownItem onClick={ () => onToggle(item.id) }>
                        <i className="mdi mdi-toggle-switch text-primary me-1" />{ " " }
                        { isDisabled ? "Enable" : "Disable" }
                      </DropdownItem>

                      { hasAccess(permission.order.read) && (
                        <DropdownItem onClick={ () => onEdit(item.id) }>
                          <i className="mdi mdi-pencil font-size-16 text-success me-1" />{ " " }
                          Edit
                        </DropdownItem>
                      ) }
                      { hasAccess(permission.order.delete) && (
                        <DropdownItem onClick={ () => onConfirmDelete(item.id) }>
                          <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{ " " }
                          Delete
                        </DropdownItem>
                      ) }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className="py-2">
            <Row>
              <Col>
                <div className="flex-grow-1 ms-2">
                  <CardTitle>Info</CardTitle>
                  <CardSubtitle className="font-size-13">
                    <span className="me-2 text-muted">User Id:</span>
                    <span className="ms-2">{ userUniqueId }</span>
                  </CardSubtitle>
                </div>
              </Col>
              <Col>
                <CardTitle>Balance</CardTitle>
                <CardSubtitle className="font-size-13">
                  <span className="me-2 text-muted">Balance:</span>
                  <span className="ms-2">{ balance }</span>
                </CardSubtitle>
              </Col>
              <Col>
                <CardTitle>Currency</CardTitle>
                <CardSubtitle className="font-size-13">
                  <span className="me-2 text-muted">Currency</span>
                  <span className="ms-2">{ currencyLabel }</span>
                </CardSubtitle>
              </Col>
            </Row>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};

const WalletTable = ({
                       itemCount,
                       page,
                       totalPage,
                       defaultPageSize,
                       items,
                       onEdit,
                       onConfirmDelete,
                       onToggle,
                       onChangePage,
                       onPageSizeChange
                     }) => {
  return (
    <div>
      { items.map((item, index) => {
        const num = (page - 1) * defaultPageSize + (index + 1);
        return (
          <WalletCard
            key={ item.id }
            item={ item }
            num={ num }
            onEdit={ onEdit }
            onConfirmDelete={ onConfirmDelete }
            onToggle={ onToggle }
          />
        );
      }) }
      <CustomPagination
        itemCount={ itemCount }
        currentPage={ page }
        totalPage={ totalPage }
        defaultPageSize={ defaultPageSize }
        pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
        onChangePage={ onChangePage }
        onPageSizeChange={ onPageSizeChange }
      />
    </div>
  );
};

WalletTable.propTypes = {
  itemCount: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default WalletTable;
