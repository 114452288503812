import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";

const PickupRowCard = ({ item, onViewDetail, onApprove, onReject }) => {
  const { attachments } = item;
  const displayStatus = item => {
    const { statusName, status } = item;
    let color = "info";

    switch (status) {
      case 0:
        color = "info";
        break;
      case 1:
        color = "warning";
        break;
      case 2:
        color = "success";
        break;
      case 3:
        color = "danger";
        break;
      case 4:
        color = "dark";
        break;
      default:
        color = "info";
        break;
    }

    return (
      <Badge color={color} className="p-2 btn-xs btn-outline">
        <span>{statusName}</span>
      </Badge>
    );
  };
  return (
    <Card className="mb-2 flex-row">
      {attachments && attachments.length > 0 ? (
        <CardImg
          style={{ width: "200px", height: "150px", objectFit: "cover" }}
          src={attachments[0].url}
          alt={attachments[0].fileName}
        />
      ) : (
        <div
          style={{
            width: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: "80px" }}>
            <i className="fas fa-image text-primary"></i>
          </div>
        </div>
      )}

      <CardBody className="flex-grow">
        <Row>
          <Col md={4} className="px-1">
            <CardTitle className="mb-2">Korean Pickup Contact</CardTitle>
            <CardBody className="px-0 py-0">
              <div className="mb-2">
                <p className="mb-2">
                  <span className="mb-0">Name:</span>
                  <span className="text-muted ps-3 mb-2">
                    {item.contactName}
                  </span>
                </p>
                <p className="mb-2">
                  <span className="mb-0">Phone:</span>
                  <span className="text-muted ps-3 mb-2">{item.phone}</span>
                </p>
                <p className="mb-2">
                  <span className="mb-0">Address:</span>
                  <span className="text-muted ps-3 mb-2">{item.address}</span>
                </p>
              </div>
            </CardBody>
          </Col>
          <Col md={4} className="px-1">
            <CardTitle className="mb-2">Cambodian Shipping Contact</CardTitle>
            <CardBody className="px-0 py-0">
              <div className="mb-2">
                <p className="mb-2">
                  <span className="mb-0">Name:</span>
                  <span className="text-muted ps-3 mb-2">
                    {item.shippingContactName}
                  </span>
                </p>
                <p className="mb-2">
                  <span className="mb-0">Phone:</span>
                  <span className="text-muted ps-3 mb-2">
                    {item.shippingContactPhone}
                  </span>
                </p>
                <p className="mb-2">
                  <span className="mb-0">Address:</span>
                  <span className="text-muted ps-3 mb-2">
                    {item.shippingContactAddress}
                  </span>
                </p>
              </div>
            </CardBody>
          </Col>

          <Col md={4} className="px-1">
            {displayStatus(item)}
            <p className="mb-2">
              <span className="mb-0">Requested By:</span>
              <span className="text-muted ps-3 mb-2">
                {item.firstName} {item.lastName}
              </span>
            </p>
            <p className="mb-2">
              <span className="mb-0">Date:</span>
              <span className="text-muted ps-3 mb-2">
                {moment
                  .utc(item.createdDate)
                  .local()
                  .format("DD-MMM-yyyy h:mm:ss a")}
              </span>
            </p>
            <p className="mb-2">
              <span className="mb-0">Note:</span>
              <span className="text-muted ps-3 mb-2">{item.note}</span>
            </p>
          </Col>
        </Row>
        <div style={{ position: "absolute", top: "10px", right: "20px" }}>
          <UncontrolledDropdown>
            <DropdownToggle className="card-drop" tag="a">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={() => onViewDetail(item.id)}>
                <i className="font-size-16 text-primary me-1" />
                View Detail
              </DropdownItem>
              <DropdownItem onClick={() => onApprove(item.id)}>
                <i className="font-size-16 text-primary me-1" />
                Approve
              </DropdownItem>
              <DropdownItem onClick={() => onReject(item.id)}>
                <i className="font-size-16 text-primary me-1" />
                Reject
              </DropdownItem>

              {/* <DropdownItem
                                     onClick={() => onConfirmDelete(item.id)}
                                   >
                                     <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                     Delete
                                   </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* <span className="font-size-24 text-primary me-2">
            <i className={true ? "fas fa-angle-up" : "fas fa-angle-down"}></i>
          </span> */}
        </div>
      </CardBody>
    </Card>
  );
};

PickupRowCard.propTypes = {
  item: PropTypes.object,
  onViewDetail: PropTypes.func,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
};

export default PickupRowCard;
