import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Badge, Card, CardBody, Table } from "reactstrap";

class PaymentHistoryListTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  displayPaymentStatus = item => {
    const { paymentStatus, paymentStatusName } = item;
    let color = "info";

    switch (paymentStatus) {
      case 0:
        color = "info";
        break;
      case 1:
        color = "warning";
        break;
      case 2:
        color = "success";
        break;
      case 3:
        color = "danger";
        break;
    }

    return (
      <Badge className="p-2" color={color}>
        <span>{paymentStatusName}</span>
      </Badge>
    );
  };

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onChangePage,
      onPageSizeChange,
    } = this.props;

    return (
      <div>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="table-responsive pb-3" data-pattern="priority-columns">
                  <div className="b-3">
                    <CustomPagination
                      itemCount={itemCount}
                      currentPage={currentPage}
                      totalPage={totalPage}
                      defaultPageSize={defaultPageSize}
                      pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                      onChangePage={(i) => onChangePage(i)}
                      onPageSizeChange={(size) => onPageSizeChange(size)}
                    />
                  </div>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered table-nowrap align-middle"
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Invoice No</th>
                        <th>Invoice Date</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Payment Date</th>
                        <th>Status</th>
                        <th>Note</th>
                        <th>Created By</th>
                        <th>Created Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => {
                        let num =
                          (currentPage - 1) * defaultPageSize + (index + 1);
                        return (
                          <tr key={index}>
                            <td className="text-end">{num}</td>
                            <td>{item.invoiceNo}</td>
                            <td>
                              {moment
                                .utc(item.invoiceDate)
                                .local()
                                .format("DD-MMM-yyyy")}
                            </td>
                            <td className="text-end">
                              {
                                <NumberFormat
                                  value={item.amount}
                                  thousandSeparator={true}
                                  fixedDecimalScale={false}
                                  displayType="text"
                                />
                              }
                            </td>
                            <td>{item.paymentMethodName}</td>
                            <td>
                              {moment
                                .utc(item.date)
                                .local()
                                .format("DD-MMM-yyyy")}
                            </td>
                            <td>{this.displayPaymentStatus(item)}</td>
                            <td>{item.note}</td>
                            <td>{item.createdByUser}</td>
                            <td>
                              {moment
                                .utc(item.createdDate)
                                .local()
                                .format("DD-MMM-yyyy h:mm:ss a")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

PaymentHistoryListTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default PaymentHistoryListTable;
