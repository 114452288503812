import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown
} from "reactstrap";
import moment from "moment";
import NumberFormat from "react-number-format";

const SaleOrderInvoiceTable = ({
                          itemCount,
                          page,
                          totalPage,
                          defaultPageSize,
                          items,
                          onEdit,
                          onExportToPdf,
                          onConfirmDelete,
                          onChangePage,
                          onPageSizeChange,
                        }) => {

  const displayStatus = item => {
    let color = ""
    switch (item.status) {
      case 0:
        color = "info"
        break
      case 1:
        color = "info"
        break
      case 2:
        color = "success"
        break
      case 3:
        color = "danger"
        break
      case 4:
        color = "warning"
        break
      case 5:
          color = "dark"
          break
    }
    return (
        <Badge color={ color } className="px-2 py-1 btn-xs btn-outline">
          <span>{ item.statusName }</span>
        </Badge>
    )
  }
  
  console.log(items);
  

  return (
      <Card>
          <div className="mx-4 mt-3">
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ page }
                totalPage={ totalPage }
                defaultPageSize={ defaultPageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={ i => onChangePage(i) }
                onPageSizeChange={ size => onPageSizeChange(size) }
            />
          </div>
        <CardBody>
          <div className="table-rep-plugin">

            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
              <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
              >
                <thead>
                <tr>
                  <th className={"text-center"} style={ { width: "80px" } }>#</th>
                  <th>Invoice No</th>
                  <th>Order No</th>
                  <th>Status</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Due Date</th>
                  <th>Amount</th>
                  <th className={"text-center"} style={ { width: "120px" } }>Action</th>
                </tr>
                </thead>
                <tbody>
                { items.map((item, index) => {
                  let num = (page - 1) * defaultPageSize + (index + 1);
                  return (
                      <tr key={ index } style={ { cursor: "pointer" } } onDoubleClick={ () => onEdit(item.id) }>
                        <td style={ { textAlign: "right" } }>{ num }</td>
                        <td>{ item.invoiceNo }</td>
                        <td>{ item?.saleOrder?.orderNo }</td>
                        <td>{ displayStatus(item) }</td>
                        <td>{ item.invoiceToUser }</td>
                        <td className={"text-end"}>{ moment(item.date).format("DD-MMM-yyyy")}</td>
                        <td className={"text-end"}>{ moment(item.dueDate).format("DD-MMM-yyyy")}</td>
                        <td className="text-end">
                          <NumberFormat
                              value={ item.totalAmount }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              prefix={"$"}
                          />
                        </td>
                        <td>
                          <div className="text-center">
                            <UncontrolledDropdown>
                              <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18"/>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={ () => onEdit(item.id) }>
                                  <i className="mdi mdi-clipboard-list-outline font-size-16 text-primary me-1"/>{ " " }
                                  Detail
                                </DropdownItem>

                                <DropdownItem divider/>

                                <DropdownItem
                                    onClick={() => onExportToPdf(item.id)}
                                  >
                                    <i className="mdi mdi-file-pdf font-size-16 text-primary me-1" />{" "}
                                    Export to PDF
                                </DropdownItem>

                                <DropdownItem
                                    onClick={ () => onConfirmDelete(item.id) }
                                >
                                  <i className="mdi mdi-delete font-size-16 text-danger me-1"/>{ " " }
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </td>
                      </tr>
                  );
                }) }
                </tbody>
              </Table>
            </div>
          </div>
          <CustomPagination
              itemCount={ itemCount }
              currentPage={ page }
              totalPage={ totalPage }
              defaultPageSize={ defaultPageSize }
              pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
              onChangePage={ i => onChangePage(i) }
              onPageSizeChange={ size => onPageSizeChange(size) }
          />
        </CardBody>
      </Card>
  );
};

SaleOrderInvoiceTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onExportToPdf: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default SaleOrderInvoiceTable;
