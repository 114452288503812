import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PICKUP,
  ADD_NEW_PICKUP,
  GET_PICKUP_BY_ID,
  UPDATE_PICKUP,
  DELETE_PICKUP,
  APPROVE_PICKUP,
  REJECT_PICKUP,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPickupSuccess,
  getPickupByIdSuccess,
  getPickupFail,
  addNewPickupSuccess,
  updatePickupSuccess,
  deletePickupSuccess,
  getApprovePickupByIdSuccess,
  getRejectPickupByIdSuccess,
} from "./actions";

const getPickupRequest = query => post(`/api/Pickup/pagingPortal`, query);
const addNewPickupRequest = data => post(`/api/Pickup`, data);
const getPickupByIdRequest = id => get(`/api/Pickup/${id}`);
const getApprovePickupByIdRequest = params =>
  post(`/api/Pickup/approve`, params);
const getRejectPickupByIdRequest = params => post(`/api/Pickup/reject`, params);
const updatePickupRequest = data => update(`/api/Pickup`, data);
const deletePickupRequest = id => del(`/api/Pickup/${id}`);

function* fetchPickups({ payload }) {
  try {
    const response = yield call(getPickupRequest, payload);

    yield put(getPickupSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupFail(error));
  }
}

function* addNewPickup({ payload }) {
  try {
    yield call(addNewPickupRequest, payload);
    const { orderId } = payload;
    const response = yield call(getPickupRequest, { orderId });
    yield put(getPickupSuccess(response));
    yield put(addNewPickupSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupFail(error));
  }
}

function* updatePickup({ payload }) {
  try {
    yield call(updatePickupRequest, payload);
    const { orderId } = payload;
    const response = yield call(getPickupRequest, { orderId });
    yield put(getPickupSuccess(response));
    yield put(updatePickupSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupFail(error));
  }
}

function* getPickupById({ payload }) {
  try {
    let response = yield call(getPickupByIdRequest, payload);
    yield put(getPickupByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupFail(error));
  }
}

function* getApprovePickupById({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(getApprovePickupByIdRequest, data);

    yield put(getApprovePickupByIdSuccess(response));
    
    response = yield call(getPickupRequest, queryParams);
    yield put(getPickupSuccess(response));
    
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupFail(error));
  }
}

function* getRejectPickupById({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(getRejectPickupByIdRequest, data);
    yield put(getRejectPickupByIdSuccess(response));

    response = yield call(getPickupRequest, queryParams);
    yield put(getPickupSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupFail(error));
  }
}

function* deletePickup({ payload }) {
  try {
    yield call(deletePickupRequest, payload);
    const { orderId } = payload;
    const response = yield call(getPickupRequest, { orderId });
    yield put(getPickupSuccess(response));
    yield put(deletePickupSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getPickupFail(error));
  }
}

function* PickupSaga() {
  yield takeEvery(GET_PICKUP, fetchPickups);
  yield takeEvery(ADD_NEW_PICKUP, addNewPickup);
  yield takeEvery(GET_PICKUP_BY_ID, getPickupById);
  yield takeEvery(UPDATE_PICKUP, updatePickup);
  yield takeEvery(DELETE_PICKUP, deletePickup);
  yield takeEvery(APPROVE_PICKUP, getApprovePickupById);
  yield takeEvery(REJECT_PICKUP, getRejectPickupById);
}

export default PickupSaga;
