import { call, put, takeEvery } from 'redux-saga/effects';
import {
  COMPANY_ADD_NEW,
  COMPANY_DELETE,
  COMPANY_GET_BY_ID,
  COMPANY_GET_PAGING,
  COMPANY_UPDATE,
} from './actionTypes';

import {
  addCompanySuccess,
  addCompanyError,
  getPagingCompanySuccess,
  getPagingCompanyError,
  getCompanyByIdSuccess,
  getCompanyByIdError,
  updateCompanySuccess,
  updateCompanyError,
} from './actions';

import { del, get, post, put as update } from "../../helpers/api_helper";

const getCompaniesRequest = () => get("/api/Company");
const getPagingCompanyRequest = query => get("/api/Company/paging", { params: query });
const addNewCompanyRequest = data => post("/api/Company", data);
const getCompanyByIdRequest = id => get(`/api/Company/${ id }`);
const updateCompanyRequest = data => update("/api/Company", data);
const deleteCompanyRequest = id => del(`/api/Company/${ id }`);

function* addNewCompany({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewCompanyRequest, data);
    yield put(addCompanySuccess(response));

    response = yield call(getPagingCompanyRequest, queryParams);
    yield put(getPagingCompanySuccess(response));
  } catch (error) {
    yield put(addCompanyError(error.response.statusText));
  }
}

function* updateCompany({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(updateCompanyRequest, data);
    yield put(updateCompanySuccess(response));

    response = yield call(getPagingCompanyRequest, queryParams);
    yield put(getPagingCompanySuccess(response));
  } catch (error) {
    yield put(updateCompanyError(error.response.statusText));
  }
}

function* getPagingCompany({ payload }) {
  try {
    let response = yield call(getPagingCompanyRequest, payload);
    yield put(getPagingCompanySuccess(response));
    console.log(response);
  } catch (error) {
    yield put(getPagingCompanyError(error.response.statusText));
  }
}

function* getCompanyById({ payload }) {
  try {
    let response = yield call(getCompanyByIdRequest, payload);
    yield put(getCompanyByIdSuccess(response));
  } catch (error) {
    yield put(getCompanyByIdError(error.response.statusText));
  }
}

function* deleteCompany({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteCompanyRequest, data);

    response = yield call(getPagingCompanyRequest, queryParams);
    yield put(getPagingCompanySuccess(response));
  } catch (error) {
    yield put(getPagingCompanyError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(COMPANY_ADD_NEW, addNewCompany);
  yield takeEvery(COMPANY_UPDATE, updateCompany);
  yield takeEvery(COMPANY_GET_PAGING, getPagingCompany);
  yield takeEvery(COMPANY_GET_BY_ID, getCompanyById);
  yield takeEvery(COMPANY_DELETE, deleteCompany);
}

export {
  getCompaniesRequest,
  getCompanyByIdRequest,
  addNewCompanyRequest,
}