
import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import { 
  getPagingCompany,
  addCompany,
  updateCompany,
  getCompanyById,
  deleteCompany,
 } from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { debounce } from "lodash";
import ModalForm from "containers/company/ModalForm";
import CompanyTable from "containers/company/CompanyTable";

const CompanyList = (props) => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const {
    getPagingCompany,
    addCompany,
    updateCompany,
    getCompanyById,
    deleteCompany,
    company,
  } = props;
  

  const {
    items,
    item,
    loading,
    itemCount,
    pageCount
  } = company;
  
  
  
  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 1000), [])

  useEffect(() => {
    getPagingCompany({ page, pageSize, term });
  }, [ page, pageSize, term ]);
  
  useEffect(() => {
    console.log("Updated company state:", company);
  }, [company]);
  

  const handleOnEdit = id => {
    getCompanyById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = data => {
    updateCompany({
      data,
      queryParams: {}
    });
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = data => {
    addCompany({
      data,
      queryParams: {}
    });
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteCompany({
      data: id,
      queryParams: {}
    });
    setModalConfirmDeleteIsOpen(false);
  };


  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Company | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Company" breadcrumbItem="Company List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ (e) => {
                                  debouncedFetchData(e.target.value)
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 4 }>
                        <div className="text-sm-end">
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                {
                  loading ? <>
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary m-1" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>:
                      <>
                        { items && (
                            <CompanyTable
                                items={ items }
                                itemCount={ itemCount }
                                page={ page }
                                totalPage={ pageCount }
                                loading={ loading }
                                defaultPageSize={ pageSize }
                                onEdit={ handleOnEdit }
                                onConfirmDelete={ handleConfirmDelete }
                                onChangePage={ (page) => setPage(page) }
                                onPageSizeChange={ (pageSize) => {
                                  setPage(1);
                                  setPageSize(pageSize);
                                } }
                            />
                        ) }
                      </>
                }
                
                { item && (
                    <ModalForm
                        title={ "Edit Company" }
                        item={ item }
                        isOpen={ modalEditIsOpen }
                        toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                        onSubmit={ handleUpdate }
                    />
                ) }
                <ModalForm
                    title={ "Add Company" }
                    isOpen={ modalAddIsOpen }
                    toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
                    onSubmit={ handleSubmit }
                />
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                    onSubmit={ handleDelete }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

CompanyList.propTypes = {
  company: PropTypes.object,
  getPagingCompany: PropTypes.func,
  addCompany: PropTypes.func,
  updateCompany: PropTypes.func,
  getCompanyById: PropTypes.func,
  deleteCompany: PropTypes.func,
};

const mapStateToProps = ({ company }) => {
  return {
    company
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingCompany,
      addCompany,
      updateCompany,
      getCompanyById,
      deleteCompany,
    })(CompanyList)
);
