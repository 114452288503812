import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import { debounce, join } from "lodash";
import { shippingContactService } from "../../services/shipping-contact-service";
import ModalFormShippingContact from "./ModalFormShippingContact";
import { addNewShippingContactRequest, 
  getShippingContactByIdRequest
} from "../../store/shipping-contact/saga";

const formatOptionLabel = ({ label, phones, address }) => {
  let phoneArr = phones
    ? phones.map(phone => {
      return phone.phoneNumber;
    })
    : [];
  return (
    <div style={ { display: "flex", flexDirection: "column" } }>
      <span className="mb-1">
        <i className="fas fa-user"></i>: <strong>{ label }</strong>
      </span>
      <span className="mb-1">
        <i className="fas fa-phone"></i>: { join(phoneArr, ", ") }
      </span>
      <span className="mb-1">
        <i className="fas fa-address-book"></i>: { address }
      </span>
    </div>
  );
};

const CreatableShippingContactSelect = props => {
  const { name, value, placeholder, userId, onChange } = props;
  const [ options, setOptions ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ term, setTerm ] = useState("");
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ contactName, setContactName ] = useState("");
  
  useEffect(() => {
    if (userId) {
      populateOptions();
    }
  }, [ userId, term]);

  const populateOptions = () => {
    if(userId){
      shippingContactService.getPagingRequest({
        page: 1,
        pageSize: 10,
        userId: userId,
        term,
      }).then(res => {
        const { data } = res;
        let items = data.map(item => {
          const { id, contact, phones, address } = item;
          return {
            key: id,
            value: id,
            label: contact,
            phones,
            address
          };
        });
        if(items.length > 0){
          onChange(items[0], {name});
        }
        setOptions(items);
      });
    }
  };

  const debouncedSearchUser = useCallback(
    debounce(term => {
      setTerm(term);
    }, 500),
    []
  );

  const handleOnChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    
    if (action === "create-option") {
      setModalIsOpen(true);
      setContactName(newValue.value);
    } else {
      onChange(newValue, actionMeta);
    }
  };

  const handleSubmit = (shippingAddress) => {
    addNewShippingContactRequest(shippingAddress)
      .then(id => {
        const { userId } = shippingAddress;
        getShippingContactByIdRequest(id).then(data => {
          if (data) {
            const payload ={
              key: data.id,
              value: data.id,
              label: data.contact,
              phones: data.phones,
              address: data.address
            };
            
            console.log(payload);
            onChange(payload, {
              name
            });
            setOptions([...options, payload]);
            setModalIsOpen(false);
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <CreatableSelect
        name={ name }
        value={ value }
        onChange={ handleOnChange }
        options={ options }
        placeholder={ placeholder }
        onInputChange={ value => debouncedSearchUser(value) }
        className="custom-shipping-addr"
        classNamePrefix="select2-selection"
        formatOptionLabel={ formatOptionLabel }
        isLoading={ loading }
        isClearable
      />
      <ModalFormShippingContact
        title={ "Add New Shipping Contact" }
        contact={ contactName }
        userId={ userId }
        isOpen={ modalIsOpen }
        toggle={ () => setModalIsOpen(!modalIsOpen) }
        onSubmit={ handleSubmit }
      />
    </>
  );
};

CreatableShippingContactSelect.propTypes = {
  userId: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default CreatableShippingContactSelect;