import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MetaTags } from "react-meta-tags";

import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, FormGroup, Input, Row, } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  clearDeliveryToDoorNotify,
  getApproveDeliveryToDoorById,
  getDeliveryToDoor,
  getDeliveryToDoorById,
  getRejectDeliveryToDoorById,
  addNewInvoice,
  clearInvoiceNotify
} from "store/actions";
import moment from "moment";
import { debounce } from "lodash";
import { toastMessage } from "helpers/utils";
import DeliveryToDoorTable from "../../containers/delivery-to-door/DeliveryToDoorTable";
import MapViewModel from "../../containers/delivery-to-door/MapViewModel";
import DeliveryToDoorViewModal from "../../containers/delivery-to-door/DeliveryToDoorViewModal";
import DeliveryToDoorConfirmModalForm from "../../containers/delivery-to-door/DeliveryToDoorConfirmModalForm";
import DoorToDoorInvoiceModal from "../../containers/invoice/DoorToDoorInvoiceModal";
import RowCard from "../../containers/delivery-to-door/RowCard";
import DeliveryToDoorCard from "../../containers/delivery-to-door/DeliveryToDoorCard";
import CustomPagination from "../../components/Common/CustomPagination";

const DeliveryToDoorList = props => {
  const [ selectedId, setSelectedId ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ term, setTerm ] = useState("");
  const [ isExpand, setIsExpand ] = useState(false);
  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ modalConfirmIsOpen, setModalConfirmIsOpen ] = useState(false);
  const [ confirmType, setConfirmType ] = useState("");
  const [ modalMapView, setModalMapView ] = useState(false);
  const [ modalInvoice, setModalInvoice] = useState(false);
  const [ selectedItem, setSelectedItem ] = useState(null);

  const {
    getDeliveryToDoor,
    getDeliveryToDoorById,
    clearDeliveryToDoorNotify,
    getApproveDeliveryToDoorById,
    getRejectDeliveryToDoorById,
    addNewInvoice,
    deliveryToDoor,
    history,
    invoice
  } = props;
  const { items, item, message, itemCount, pageCount, loading } = deliveryToDoor;
  
  console.log(item);

  useEffect(() => {
    getDeliveryToDoor({ page, pageSize, term });
  }, [ getDeliveryToDoor, page, pageSize, term ]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearDeliveryToDoorNotify();
    }
  }, [ message ]);

  useEffect(() => {
    if (invoice.message) {
      toastMessage(invoice.message);
      clearInvoiceNotify();
    }
  }, [invoice.message]);

  const debouncedFetchData = useCallback(
      debounce(term => {
        setTerm(term);
      }, 1000),
      []
  );

  const handleSearch = () => {
    getDeliveryToDoor({
      page,
      pageSize,
      term,
      startDate: startDate
          ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : null,
      endDate: endDate
          ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : null,
    });
  };

  const toggleExpand = () => {
    setIsExpand(!isExpand);
  };

  const handleOnPageChange = page => {
    setPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPage(1);
    setPageSize(pageSize);
  };

  const handleOnViewDetail = id => {
    console.log(id);
    getDeliveryToDoorById(id);
    setModalIsOpen(true);
  };

  const handleApprove = data => {
    getApproveDeliveryToDoorById(data);
    setModalIsOpen(false);
  };

  const handleReject = data => {
    getRejectDeliveryToDoorById(data);
    setModalIsOpen(false);
  };
  
  const handleOnGeneratingInvoice=(item)=>{
    setSelectedItem(item);
    setModalInvoice(true);
  }
  
  const handleSubmitGenerateInvoice=data=>{
    addNewInvoice(data);
    setModalInvoice(false);
  }

  const handleSubmit = data => {
    const { confirmType, id, comment } = data;
    const {
      contactName,
      phone,
      address,
      note,
    } = item;
    switch (confirmType) {
      case "Approve":
        getApproveDeliveryToDoorById({
          id: id,
          contactName,
          phone,
          address,
          note,
          comment,
        });
        break;
      case "Reject":
        getRejectDeliveryToDoorById({
          id,
          contactName,
          phone,
          address,
          note,
          comment,
        });
        break;
      default:
        break;
    }
    setModalConfirmIsOpen(false);
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Delivery To Door List | JLS Express</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Delivery To Door List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-2">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ e => debouncedFetchData(e.target.value) }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 2 }>
                        <FormGroup>
                          <ReactDatePicker
                              className="form-control"
                              selectsStart
                              name="startDate"
                              selected={ startDate }
                              startDate={ startDate }
                              endDate={ endDate }
                              onChange={ date => setStartDate(date) }
                              dateFormat="dd-MMM-yyyy"
                              placeholderText="Start Date"
                              isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 2 }>
                        <FormGroup>
                          <ReactDatePicker
                              className="form-control"
                              name="endDate"
                              selectsEnd
                              selected={ endDate }
                              startDate={ startDate }
                              endDate={ endDate }
                              onChange={ date => setEndDate(date) }
                              dateFormat="dd-MMM-yyyy"
                              placeholderText="End Date"
                              isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 2 }>
                        <Button
                            type="button"
                            outline
                            color="primary"
                            className="d-lg-none w-100"
                            onClick={ handleSearch }
                        >
                          <i className="fas fa-filter"/> Filter
                        </Button>

                        <Button
                            type="button"
                            outline
                            color="primary"
                            className="d-none d-lg-block"
                            onClick={ handleSearch }
                        >
                          <i className="fas fa-filter"/> Filter
                        </Button>
                      </Col>
                      <Col md={ 4 }>
                        <Row>
                          <Col md={ 11 }>
                            <div className="text-sm-end">
                              {/* <Button
                              type="button"
                              color="primary"
                              outline
                              size="md"
                              onClick={handleExportToExcel}
                            >
                              <i className="fas fa-file-excel" /> Export to
                              Excel
                            </Button> */ }
                            </div>
                          </Col>
                          <Col md={ 1 }>
                            <div onClick={ toggleExpand }>
                            <span className="font-size-24 text-primary me-2">
                              <i
                                  className={
                                    isExpand
                                        ? "fas fa-angle-up"
                                        : "fas fa-angle-down"
                                  }
                              ></i>
                            </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ isExpand }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Card>
                  <CardBody className="p-2">
                    <CustomPagination
                        itemCount={itemCount}
                        currentPage={page}
                        totalPage={pageCount}
                        defaultPageSize={pageSize}
                        pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                        onChangePage={p => setPage(p)}
                        onPageSizeChange={size => {
                          setPage(1);
                          setPageSize(size);
                        }}
                    />
                  </CardBody>
              </Card>
              <Col lg="12">
                { loading ? (
                    <div className="d-flex justify-content-center">
                      <div
                          className="spinner-border text-primary m-1"
                          role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                ) : (
                    <>
                      { items && (
                          <>

                            {items.map((item,index) =>{
                              return <DeliveryToDoorCard 
                                      key={index} 
                                      item={item}
                                      onViewDetail={handleOnViewDetail}
                                      onGenerateInvoice={ handleOnGeneratingInvoice}
                                      onMapView={item =>{
                                        setModalMapView(true)
                                        setSelectedItem(item);
                                      }}/>
                            })}
                            <Card>
                              <CardBody className="p-2">
                                <CustomPagination
                                    itemCount={itemCount}
                                    currentPage={page}
                                    totalPage={pageCount}
                                    defaultPageSize={pageSize}
                                    pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                                    onChangePage={p => setPage(p)}
                                    onPageSizeChange={size => {
                                      setPage(1);
                                      setPageSize(size);
                                    }}
                                />
                              </CardBody>
                            </Card>
                          {/*<DeliveryToDoorTable*/}
                          {/*    items={ items }*/}
                          {/*    itemCount={ itemCount }*/}
                          {/*    totalPage={ pageCount }*/}
                          {/*    page={ page }*/}
                          {/*    pageSize={ pageSize }*/}
                          {/*    onChangePage={ handleOnPageChange }*/}
                          {/*    onPageSizeChange={ handleOnPageSizeChange }*/}
                          {/*    onGenerateInvoice={ handleOnGeneratingInvoice}*/}
                          {/*    onViewDetail={ handleOnViewDetail }*/}
                          {/*    onApprove={ id => {*/}
                          {/*      setSelectedId(id);*/}
                          {/*      getDeliveryToDoorById(id);*/}
                          {/*      setConfirmType("Approve");*/}
                          {/*      setModalConfirmIsOpen(true);*/}
                          {/*    } }*/}
                          {/*    onReject={ id => {*/}
                          {/*      setSelectedId(id);*/}
                          {/*      getDeliveryToDoorById(id);*/}
                          {/*      setConfirmType("Reject");*/}
                          {/*      setModalConfirmIsOpen(true);*/}
                          {/*    } }*/}
                          {/*    onMapView={ item => {*/}
                          {/*      setModalMapView(true)*/}
                          {/*      setSelectedItem(item);*/}
                          {/*    } }*/}
                          {/*/>*/}
                          </>
                      ) }
                    </>
                ) }

                { item && (
                    <DeliveryToDoorViewModal
                        title={ "Delivery To Door Detail" }
                        isOpen={ modalIsOpen }
                        toggle={ () => setModalIsOpen(!modalIsOpen) }
                        item={ item }
                        onApprove={ handleApprove }
                        onReject={ handleReject }
                    />
                ) }

                <DeliveryToDoorConfirmModalForm
                    id={ selectedId }
                    isOpen={ modalConfirmIsOpen }
                    confirmType={ confirmType }
                    toggle={ () => setModalConfirmIsOpen(!modalConfirmIsOpen) }
                    onSubmit={ handleSubmit }
                />
                { selectedItem &&
                    <MapViewModel
                        title={ "View Map" }
                        isOpen={ modalMapView }
                        latitude={ selectedItem.latitude }
                        longitude={ selectedItem.longitude }
                        toggle={ () => setModalMapView(!modalMapView) }
                    />
                }
                { selectedItem &&
                  <DoorToDoorInvoiceModal
                      isOpen={modalInvoice}
                      toggle={()=> setModalInvoice(!modalInvoice)}
                      title={"Add Delivery Fee Amount"}
                      deliveryToDoor={selectedItem}
                      onSubmit={handleSubmitGenerateInvoice}
                  />
                }
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

DeliveryToDoorList.propTypes = {
  message: PropTypes.object,
  deliveryToDoor: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,

  getDeliveryToDoor: PropTypes.func,
  getDeliveryToDoorById: PropTypes.func,
  clearDeliveryToDoorNotify: PropTypes.func,
  getApproveDeliveryToDoorById: PropTypes.func,
  getRejectDeliveryToDoorById: PropTypes.func,
  history: PropTypes.object,
  addNewInvoice: PropTypes.func,
  clearInvoiceNotify: PropTypes.func,
};

const mapStateToProps = ({ deliveryToDoor, invoice }) => {
  return { 
    deliveryToDoor,
    invoice 
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getDeliveryToDoor,
      getDeliveryToDoorById,
      clearDeliveryToDoorNotify,
      getApproveDeliveryToDoorById,
      getRejectDeliveryToDoorById,
      addNewInvoice,
      clearInvoiceNotify
    })(DeliveryToDoorList)
);
