import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { del, get, post, put } from "../../helpers/api_helper";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
import { facebook, google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import jlsExpressLogo from "../../assets/images/logo.png";
import lightlogo from "../../assets/images/logo-light.svg";
import { withSettings } from "../../hook/withSettings";
import MetaTags from "react-meta-tags";
import { getCompanyBrand, getCompanyName, JLS_EXPRESS, JLS_EXPRESS_SANDBOX } from "../../helpers/utils";
import jlsSm from "../../assets/images/jls_express_logo-sm.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import { off } from "leaflet/src/dom/DomEvent";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      userName: null,
      userPassword: null,
      firstName: "",
      lastName: "",
      loading: false,
      isShow: false,
    };
  }

  toggleShowhide = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }));
  };

  componentDidMount() {
    this.props.apiError("");
  }

  getLogo=()=>{
    switch (getCompanyBrand()){
      case JLS_EXPRESS:
        return jlsExpressLogo;
      case JLS_EXPRESS_SANDBOX:
        return jlsExpressLogo;
      default:
        return logo;
    }
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    }
  };

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google");
  };

  //handleTwitterLoginResponse
  twitterResponse = () => {};

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook");
  };

  render() {
    const { isShow } = this.state;
    const { company } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Dashboard | {getCompanyName()}</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            Welcome to {company?.name}!
                          </h5>
                          <p>Login to manage items</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={this.getLogo()}
                              alt=""
                              className="rounded-circle"
                              height="60"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          userName: this.state.userName ?? "",
                          userPassword: this.state.userPassword ?? "",
                        }}
                        validationSchema={Yup.object().shape({
                          userName: Yup.string().required(
                            "Please Enter Your Username"
                          ),
                          userPassword: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                        })}
                        onSubmit={values => {
                          const { userName, userPassword } = values;
                          
                          const payload = {
                            username: userName,
                            password: userPassword,
                          }
                          
                          this.props.loginUser(payload, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form autoComplete="off" className="form-horizontal">
                            <div className="mb-3">
                              <Label for="user" className="form-label">
                                Username
                              </Label>
                              <Field
                                name="userName"
                                type="text"
                                autoComplete="off"
                                className={
                                  "form-control" +
                                  (errors.userName && touched.userName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="userName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  id="userPassword"
                                  name="userPassword"
                                  type={isShow ? "text" : "password"}
                                  autoComplete="new-password"
                                  className={
                                    "form-control" +
                                    (errors.userPassword && touched.userPassword
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={this.toggleShowhide}
                                >
                                  <i
                                    className={
                                      "mdi " +
                                      (isShow
                                        ? "mdi-eye-off-outline"
                                        : "mdi-eye-outline")
                                    }
                                  ></i>
                                </button>
                              </div>
                              <ErrorMessage
                                name="userPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                {this.state.loading ? (
                                  <span
                                    style={{ width: "1rem", height: "1rem" }}
                                    className="spinner-border"
                                  ></span>
                                ) : (
                                  <span></span>
                                )}{" "}
                                Log In
                              </button>
                            </div>

                            {/* <div className="mt-4 text-center">
                              <h5 className="font-size-14 mb-3">
                                Sign in with
                              </h5>

                              <ul className="list-inline">
                                <li className="list-inline-item">
                                  <FacebookLogin
                                    appId={facebook.APP_ID}
                                    autoLoad={false}
                                    callback={this.facebookResponse}
                                    render={renderProps => (
                                      <Link
                                        to={""}
                                        className="social-list-item bg-primary text-white border-primary"
                                      >
                                        <i className="mdi mdi-facebook" />
                                      </Link>
                                    )}
                                  />
                                </li>
                                <li className="list-inline-item">
                                  {google.CLIENT_ID === "" ? (
                                    ""
                                  ) : (
                                    <GoogleLogin
                                      clientId={google.CLIENT_ID}
                                      render={renderProps => (
                                        <Link
                                          to={""}
                                          className="social-list-item bg-danger text-white border-danger"
                                        >
                                          <i className="mdi mdi-google" />
                                        </Link>
                                      )}
                                      onSuccess={this.googleResponse}
                                      onFailure={() => {}}
                                    />
                                  )}
                                </li>
                              </ul>
                            </div> */}

                            {/* <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div> */}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} {company?.name} by CR TechSoft
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = ({Login }) => {
  const { wallet } = Login;
  return {
    wallet,
  };
};

export default withSettings(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
